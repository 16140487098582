export const PROTOCOL = "https://";
export const URL = "imcbrokerlik.com/";
export const APP_NAME = "api";
export const API_URL = `${PROTOCOL}${URL}`;
export const IMG_URL = "https://imcbrokerlik.com/";
export const IMG_URL_PORTAL = "https://portal.imcbrokerlik.com/";
export const CAREER_URL =
  "https://imecedestek.com/v3/api/careers/apply/imcbroker";

export const MASTER_IDENTIFIER = "1c73d884-f6ac-41ae-af9b-06fec3abc4f0";
export const CAREER_TOKEN =
  "SozdGA8um7WH8FMHpqHrjXTi6y0VzRWp11vjlkqbW2x0rmXtTHI9DfekBH5uS4Lm";

export const PRIVATE_KEY = "(H+MbQeThWmZq3t6w9z$C&F)J@NcRfUj";
export const IV = "TjWnZr4u7x!A%D*G";

export const API_BASE_NAME = {};

export const CURRENCY_TYPE = "₺";

// --------------------------------------------------------------------------------------------------------------------

// export const PROTOCOL = "https://";
// export const URL = "localhost:44448/";
// export const APP_NAME = "api";
// export const API_URL = `${PROTOCOL}${URL}`;
// export const IMG_URL = "https://imcbrokerlik.com/";
// export const IMG_URL_PORTAL = "https://portalpreprod.imcbrokerlik.com/";
// export const CAREER_URL = "https://preprod.imecedestek.com/";
// export const MASTER_IDENTIFIER = "1c73d884-f6ac-41ae-af9b-06fec3abc4f0";
// export const CAREER_TOKEN =
//    "SozdGA8um7WH8FMHpqHrjXTi6y0VzRWp11vjlkqbW2x0rmXtTHI9DfekBH5uS4Lm";

// export const PRIVATE_KEY = "(H+MbQeThWmZq3t6w9z$C&F)J@NcRfUj";
// export const IV = "TjWnZr4u7x!A%D*G";

// export const API_BASE_NAME = {};

// export const CURRENCY_TYPE = "₺";

import React from "react";
import { Breadcrumbs } from "../Common/Breadcrumbs";
import { HeaderImage } from "../Common/HeaderImage";
import { SectionMap } from "./SectionMap";
import ContactUs from "components/Home/ContactUs";
import { useTranslation } from "react-i18next";

export const Index = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col justify-center relative">
      <HeaderImage src="/img/contact-us.png" title={t("contact.title")} />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs title={t("contact.title")} href="contact" />
        {/*Section 1 */}
        <div className="flex justify-center items-center flex-col">
          <div className="text-dark-cerulean mt-11">
            <h1 className="text-2xl font-bold lg:w-64 border-b-2 border-b-dark-cerulean text-center leading-10 tracking-[.25em] ">
              {t("contact.title")}
            </h1>
          </div>
          <div className="grid lg:grid-cols-2 text-dark-cerulean gap-x-32 pt-7 pb-1 gap-y-2 lg:gap-y-0">
            <div className="text-center">
              <h3 className="text-2xl font-bold">0850 333 38 77</h3>
              <h5 className="font-bold">TBS</h5>
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-bold">0212 211 11 56</h3>
              <h5 className="font-bold">IMC</h5>
            </div>
          </div>
        </div>

        <SectionMap
          title={`İstanbul / ${t("contact.headquarters")}`}
          address1="Esentepe Mah. Matbuat Sok. No:5/2"
          address2="Şişli / İstanbul"
          mail="info@imcbrokerlik.com"
          phone="+90 (212) 211 11 56"
          iframeSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.9954929036658!2d29.007636879623416!3d41.06909116598947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab6507c89fead%3A0x66f21972f557f309!2zRXNlbnRlcGUsIE1hdGJ1YXQgU2suIE5vOjUgS2F0OjIsIDM0Mzk0IMWeacWfbGkvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1684742973564!5m2!1str!2str"
        />
        <SectionMap
          title={`İstanbul ${t("contact.salesOffice")}`}
          address1="Esentepe Mah. Matbuat Sok. No:5/2 Kat:1"
          address2="Şişli / İstanbul"
          mail="mustafa@imcbrokerlik.com"
          phone="+90 (212) 211 11 56"
          reverse={true}
          iframeSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.9954929036658!2d29.007636879623416!3d41.06909116598947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab6507c89fead%3A0x66f21972f557f309!2zRXNlbnRlcGUsIE1hdGJ1YXQgU2suIE5vOjUgS2F0OjIsIDM0Mzk0IMWeacWfbGkvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1684742973564!5m2!1str!2str"
        />
        <SectionMap
          title={`Ankara ${t("contact.area")}`}
          address1="Ehlibeyt Mah. Ceyhun Atıf Kansu Cad. Bayraktar İş Merkezi E Blok No:114/2"
          address2="Çankaya / Ankara"
          mail="info@imcbrokerlik.com"
          phone="+90 312 474 06 04"
          iframeSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3061.2061244425972!2d32.811772095289115!3d39.89201711400324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d345fbaf0d23ed%3A0xf34a174b57dece67!2sEhlibeyt%2C%20Ceyhun%20Atuf%20Kansu%20Cd.%2C%20%C3%87ankaya%2FAnkara!5e0!3m2!1sen!2str!4v1683701994486!5m2!1sen!2str"
        />
        <SectionMap
          title={`Bursa ${t("contact.area")}`}
          address1="Barış Mah. Lozan Sok. Fsm Atış Deluxe Sitesi A Blok K:4 D:8"
          address2="Nilüfer / Bursa"
          mail="mustafa.cemberci@imcbrokerlik.com"
          phone="+90 224 256 58 58"
          reverse={true}
          iframeSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3046.7310054916466!2d28.97116919534811!3d40.21504619434046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca1421e2793475%3A0x4cf5f73f63038515!2zQmFyxLHFnywgTG96YW4gU2suLCAxNjE0MCBOaWzDvGZlci9CdXJzYQ!5e0!3m2!1sen!2str!4v1683702202511!5m2!1sen!2str"
        />
        <SectionMap
          title={`Yalova ${t("contact.area")}`}
          address1="Ahmet Sugün Cad. No:15/A"
          address2="Çınarcık / Yalova"
          mail="yener@imcbrokerlik.com"
          phone="+90 226 245 05 55"
          iframeSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.251867778184!2d29.122060077311776!3d40.64637804162833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caf3c5750df18f%3A0x24c4e00c45b068f5!2zVGHFn2xpbWFuLCBNdWh0YXIgQWhtZXQgU3Vnw7xuIENkLiBObzoxNSBEOmEsIDc3MzAwIMOHxLFuYXJjxLFrL1lhbG92YQ!5e0!3m2!1sen!2str!4v1683702314310!5m2!1sen!2str"
        />
        <SectionMap
          title={`Mersin ${t("contact.contactOffice")}`}
          address1="350-1 Sokak No:28 Taşucu"
          address2="Silifke / Mersin"
          mail="info@imcbrokerlik.com"
          phone="+90 212 211 11 56"
          reverse={true}
          iframeSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6429.367376542486!2d33.87817816180846!3d36.31997669927455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d8dc01863d73fd%3A0x548ae7087efaa3b8!2sTa%C5%9Fucu%2C%2033900%20Silifke%2FMersin!5e0!3m2!1sen!2str!4v1683702400444!5m2!1sen!2str"
        />
      </div>
      <ContactUs />
    </div>
  );
};
